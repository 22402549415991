<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="detail.name"
    class="px-5 py-3"
    v-if="detail"
  >
    <base-registration-action-button
      :permissions="permissions"
      :basePath="basePath"
      :id="detail.id.toString()"
      v-if="detail.id && detail.status === 'PENDING'"
      @acceptAction="acceptAction"
      @rejectAction="rejectAction"
    >
    </base-registration-action-button>
    <v-row>
      <v-col cols="6" class="border--right">
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.name') }}
          </v-col>
          <v-col cols="6">
            {{ detail.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.phone') }}
          </v-col>
          <v-col cols="6">
            {{ detail.phone_number }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.email') }}
          </v-col>
          <v-col cols="6">
            {{ detail.email }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.reference') }}
          </v-col>
          <v-col cols="6">
            {{ detail.reference }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.projectType') }}
          </v-col>
          <v-col cols="6">
            {{ detail.property_type }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.priceRange') }}
          </v-col>
          <v-col cols="6">
            {{ detail.price }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.city') }}
          </v-col>
          <v-col cols="6">
            {{ detail.location }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.createdAt') }}
          </v-col>
          <v-col cols="6">
            {{ detail.created_at ? format(new Date(detail.created_at), 'dd MMMM yyyy HH:mm') : '' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.updatedAt') }}
          </v-col>
          <v-col cols="6">
            {{ detail.updated_at ? format(new Date(detail.updated_at), 'dd MMMM yyyy HH:mm') : '' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('projectInquiry.status') }}
          </v-col>
          <v-col cols="6">
            {{ detail.status }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions'],
  computed: {
    ...mapState({
      basePath: (state) => state.projectInquiry.basePath,
    }),
  },
  data() {
    return {
      format,
    };
  },
  methods: {
    async acceptAction(id) {
      console.log('ACCEPT PROJECT INQUIRIES ID: ', id);
      try {
        await this.$store.dispatch('projectInquiry/accept', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('project.accept.successMsg'),
          'success',
        );
        await this.$router.replace('/project-inquiries');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    async rejectAction(id) {
      console.log('REJECT PROJECT INQUIRIES ID: ', id);
      try {
        await this.$store.dispatch('projectInquiry/reject', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('project.reject.successMsg'),
          'success',
        );
        await this.$router.replace('/project-inquiries');
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
